<template>
    <div>
        <!-- normal ui buttons for src files -->
        <div v-show="file_upload_type == null" class="text-center">
            <div class="row">
                <div class="col-sm-6">
                    <b-button v-show="read_only == false" class="w-100" variant="success" @click="toggleCourierModal()">
                        <i class="mdi mdi-plus d-block font-size-16"></i>
                        Add Courier Info
                    </b-button>
                </div>
                <div class="col-sm-6">
                    <b-button v-show="selected_order_details.tracking_url.length > 0" class="w-100" variant="primary" :href="selected_order_details.tracking_url" target="_blank" rel="noopener noreferrer">
                        <i class="mdi mdi-truck d-block font-size-16"></i>
                        Track Courier
                    </b-button>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-6">
                    <div v-if="selected_order_details.public_invoice_src">
                        <b-button class="w-100" variant="primary" @click="openLink('invoice')">
                            <i class="mdi mdi-download d-block font-size-16"></i>
                            Invoice
                        </b-button>
                    </div>
                    <div v-else>
                        <b-button class="w-100" variant="primary" :disabled="hasSrc('invoice_src')">
                            <i class="mdi mdi-download d-block font-size-16"></i>
                            Invoice
                        </b-button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <b-button v-if="read_only == false" class="w-100" variant="success" @click="setUploadMode('invoice')">
                        <i class="mdi mdi-upload d-block font-size-16"></i>
                        Upload
                    </b-button>
                </div>


            </div>

           <!-- <hr />
            <div class="row">
                <div class="col-sm-6">
                    <div v-if="selected_order_details.public_waybill_src">
                        <b-button variant="primary" class="w-100" @click="openLink('waybill')">
                            <i class="mdi mdi-download d-block font-size-16"></i>
                            Waybill
                        </b-button>
                    </div>
                    <div v-else>
                        <b-button variant="primary" class="w-100" @click="openLink('waybill')" :disabled="hasSrc('waybill_src')">
                            <i class="mdi mdi-download d-block font-size-16"></i>
                            Waybill
                        </b-button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <b-button v-if="read_only == false" class="w-100" variant="success" @click="setUploadMode('waybill')">
                        <i class="mdi mdi-upload d-block font-size-16"></i>
                        Upload
                    </b-button>
                </div>
            </div> -->
        </div>

        <!-- html for uploader -- type is either 'invoice' or 'waybill' -->
        <div v-if="show_upload">
            <h6>
                <i class="fas fa-arrow-left mr-1 cursor-pointer" @click="file_upload_type = null"></i>
                {{ returnUploadTitle }}
            </h6>
            <uploader :options="renderUploadOptions" :autoStart="false" :ref="'attach_upload'" class="uploader-example">
                <uploader-unsupport></uploader-unsupport>
                <uploader-drop>
                    <p>Drop files here to upload or</p>
                    <uploader-btn class="btn">Select File</uploader-btn>
                    <!--<uploader-btn :attrs="attrs">select images</uploader-btn>
                <uploader-btn :directory="true">select folder</uploader-btn>-->
                </uploader-drop>
                <uploader-list></uploader-list>
            </uploader>

            <b-button variant="success" class="w-100 mt-2" @click="saveAttachment()">Save Attachment</b-button>
        </div>

        <courierModal v-if="showCourierModal"></courierModal>
    </div>
</template>

<script>
import { orderComputed, orderMethods } from "@/state/helpers";
import courierModal from "./courierModal.vue";
export default {
    props: {
        read_only: {
            default: false,
        },
    },
    data: () => ({
        show_upload: false,
        file_upload_type: null,
        showCourierModal: false,
        attrs: {
            accept: "image/*",
        },
    }),
    components: {
        courierModal,
    },
    watch: {
        file_upload_type(newVal) {
            if (newVal == null) {
                this.show_upload = false;
            } else {
                this.$nextTick(() => {
                    this.show_upload = true;
                });
            }
        },
        // selected_order_details() {
        //     //eslint-disable-next-line
        //     console.log("it was updated");
        // },
    },
    computed: {
        ...orderComputed,
        returnUploadTitle() {
            if (this.file_upload_type == null) {
                return "";
            }
            return `Upload file for ${this.file_upload_type}`;
        },
        renderUploadOptions() {
            var storage = window.localStorage;
            var AUTH_TOKEN = storage.getItem("x_token");
            var url = this.getUploadUrl;
            var options = {
                target: url,
                query: {
                    order_id: this.selected_order_details.id,
                },
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN,
                },
                testChunks: false,
                withCredentials: false,
                fileParameterName: this.file_upload_type,
                singleFile: true, //Limit upload to one file
            }; //
            return options;
        },
        getUploadUrl() {
            if (this.file_upload_type == "invoice") {
                return process.env.VUE_APP_API_BASEURL + "api/orders/uploadinvoice";
            } else {
                return process.env.VUE_APP_API_BASEURL + "api/orders/uploadwaybill";
            }
        },
    },
    methods: {
        ...orderMethods,
        //eslint-disable-next-line
        hasSrc(src_name) {
            return true;
            // return this.selected_order_details.public_invoice_src.length > 5 ? true : false;
            // return this.selected_order_details[src_name] ? true : false;
            //return this.ext_order[src_name] != null ? false : true;
        },
        toggleCourierModal() {
            this.showCourierModal = true;
            this.$nextTick(() => {
                this.$bvModal.show("saveCourierModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "saveCourierModal") {
                        this.showCourierModal = false;
                    }
                });
            });
        },
        openLink(type) {
            if (type == "invoice") {
                // let url = this.ext_order.invoice_src;
                let url = this.selected_order_details.public_invoice_src;
                window.open(url, "_blank");
            } else if (type == "waybill") {
                // let url = this.ext_order.waybill_src;
                let url = this.selected_order_details.public_waybill_src;
                window.open(url, "_blank");
            }
        },
        saveAttachment() {
            const uploaderInstance = this.$refs["attach_upload"];
            const upload = uploaderInstance.uploader;
            upload.opts.query.name = this.file_upload_type;
            // upload.opts.query.order_id = this.ext_order.id;
            upload.resume();
            //eslint-disable-next-line no-unused-vars
            upload.on("fileSuccess", (rootFile, file, message, chunk) => {
                var response = JSON.parse(message);
                if (response.success) {
                    this.$swal.fire("Uploaded", "Your attachment has been uploaded!", "success");
                    upload.off("fileSuccess");
                    upload.cancel();

                    // will refresh the button to display that it is downlaodable
                    this.getOrderDetails(this.$route.params.orderid).then(() => {
                        //this will close the uploader window
                        setTimeout(() => {
                            this.file_upload_type = null;
                        }, 50);
                    });
                } else {
                    //eslint-disable-next-line no-console
                    console.log("Upload error");
                }
            });
        },

        //* upload
        setUploadMode(type) {
            this.file_upload_type = type;
        },
    },
    mounted() {},
};
</script>

<style>
.uploader-example {
    width: 100%;
    padding: 15px;
    /*margin: 40px auto 0;*/
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.uploader-example .uploader-btn {
    width: 100%;
}
.uploader-example .uploader-list {
    max-height: 440px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
}

@media only screen and (max-width: 1800px) {
    .set-max-width {
        max-width: 30%;
    }
}

@media only screen and (max-width: 1000px) {
    .set-max-width {
        max-width: 100%;
    }
}
</style>
