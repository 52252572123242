<template>
    <div>
        <b-modal size="lg" title="Save Courier Info" hide-footer id="saveCourierModal">
            <div class="modal-body" style="width: 100%">
                <b-overlay :show="busySaving">
                    <div class="row">
                        <div class="col-sm-6">
                            <b-form-select
                                v-model="postdata.courier_id"
                                :options="availableCouriers"
                                id="courier"
                                name="courier"
                                aria-describedby="client-type-live-feedback"
                            ></b-form-select>
                        </div>
                        <div class="col-sm-6" v-show="postdata.courier_id != ''">
                            <b-form-input 
                                v-model="postdata.waybill_no"
                                placeholder="Enter waybill no."
                            ></b-form-input>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="d-flex justify-content-between mt-2"></div>
                        <b-button v-show="postdata.waybill_no != ''" type="submit" variant="primary" @click="saveCourierDetails()">Save</b-button>
                        <button type="button" class="btn btn-danger" @click="closeModal()">Close</button>
                    </div>
                </b-overlay>
            </div>
        </b-modal>

    </div>
</template>
<script>
import { orderComputed, orderMethods } from "@/state/helpers";
export default {
    data: () => ({
        postdata: {
            courier_id: "",
            waybill_no: ""
        },
        busySaving: false
    }),
    props: {

    },
    computed: {
        ...orderComputed,
        availableCouriers() {
            const defaultOption = { value: "", text: "Please select one" };
            const courierOptions = this.selected_order_details.available_couriers.map(courier => ({
                value: courier.id,
                text: courier.name
            }));
            return [defaultOption, ...courierOptions];
        }
    },
    methods: { 
        ...orderMethods,
        closeModal() {
            this.$bvModal.hide("saveCourierModal");
        },
        saveCourierDetails() {
            this.busySaving = true;
            this.postdata.order_id = this.selected_order_details.id;
            this.saveCourierInfo(this.postdata).then(() => {
                this.getOrderDetails(this.postdata.order_id).then(() => {
                    //this will close the uploader window
                    this.busySaving = false;
                    this.closeModal();
                });
            });
        }
    },
    mounted() {

    },
};
</script>